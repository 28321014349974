import 'bootstrap';
import 'hammerjs';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { DateAdapter as MaterialDateAdapter } from '@angular/material/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BootService } from '@services/boot.service';
import { CalendarModule } from 'angular-calendar';
import { UserIdleModule } from 'angular-user-idle';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { CustomDatepickerAdapter, HttpLoaderFactory, MyHammerConfig, get_menu } from './app/app.module';
import { APP_PROVIDERS } from './app/app.providers';
import { UIComponentsModule } from './app/components/ui-components.module';
import { authInterceptor } from './app/interceptors/auth.interceptor';

// Hammer js
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      UIComponentsModule,
      CalendarModule,
      DragDropModule,
      HammerModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      UserIdleModule.forRoot({
        idle: 1800,
        timeout: 300,
        ping: 120, // 2 minutes
      })
    ),
    BootService,
    { provide: APP_INITIALIZER, useFactory: get_menu, deps: [BootService], multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MaterialDateAdapter, useClass: CustomDatepickerAdapter },
    TranslateService,
    ...APP_PROVIDERS,
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
}).catch(err => console.error(err));
